

$white: #ffffff;
$black: #000000;


$primary: #D11F2A;

$secondary: #08A0C8;


$text: #333333;



$primary_font: 'sans-serif';


@import 'directories';
@import 'pagination';
//@import 'nav-standard'; // This needs to be called in the custom folder - Keep here for reference.
@import 'gutters';
@import 'typography';
@import 'mqs';