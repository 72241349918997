$nav_bar_bg: $white;
$nav_bar_bg_desk: rgba($white,.8);
$nav_bar_border: 1px solid $primary;
$nav_bar_border_desk: 1px solid $primary;

$nav_bar_font: $primary_font;

$ns_mobile_bg_desk: $primary;


$logo_original_height: 126px;
$logo_original_width: 400px;

$logo_original_height_desk: 126px;
$logo_original_width_desk: 400px;

$logo_height: 40px;
$logo_height_desk: 40px;
$logo_height_desk_scrolled: 30px;

$logo_width: ( $logo_original_width / $logo_original_height ) * $logo_height;
$logo_width_desk: ( $logo_original_width_desk / $logo_original_height_desk ) * $logo_height_desk;
$logo_width_desk_scrolled: ( $logo_original_width_desk / $logo_original_height_desk ) * $logo_height_desk_scrolled;



$logo_padding: 5px;
$logo_padding_desk: 10px;


$logo_padding_desk_scrolled: 10px;

$logo_image: '#{$images_dir}/logos/logo.png';
$logo_image_desk: '#{$images_dir}/logos/logo.png';


$nav_height: $logo_height + ( $logo_padding * 2 );
$nav_height_desk: $logo_height_desk + ( $logo_padding_desk * 2 );
$nav_height_desk_scrolled: $logo_height_desk_scrolled + ( $logo_padding_desk_scrolled * 2 );

$ns_bars_width: 50px;
$ns_bars_border: 1px solid $primary;
$ns_bars_bg: '#{$default_images_dir}/default/navBars.svg';


$anchor_color: $white;
$anchor_color_desk: $primary;
$anchor_hover_desk: $secondary;
$anchor_current_desk: $primary;
$anchor_current_weight_desk: $font-weight-bold;

$anchor_weight: $font-weight-semibold;
$anchor_size: 16px;

$anchor_line: 30px;
$anchor_line_desk: $nav_height_desk;
$anchor_line_desk_scrolled: $nav_height_desk_scrolled;

$anchor_padding: 10px;
$anchor_border: 1px solid $white;

$anchor_margin_desk: 0;

$sub_bg: $secondary;
$sub_bg_desk: rgba($white, .8);
$sub_bg_border_desk: 1px solid $primary;
$sub_current: $white;
$sub_current_desk: $secondary;

$sub_anchor: $white;
$sub_anchor_desk: $primary;

$sub_size: 16px;
$sub_size_desk: 16px;
$sub_line: 30px;
$sub_line_desk: 24px;
$sub_padding_desk: 7px 10px;

$sub_bg_hover_desk: transparent;
$sub_anchor_hover_desk: $secondary;


$body_padding:  30px;