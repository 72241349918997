$primary: #334082;
$secondary: #8e1e2b;

$gray: #262729;

$text: #414042;

@import 'directories';
@import 'typography';
@import 'nav-standard';



.font-secondary {
	font-family: $font_open;
}


