

$cal_border: red;
$cal_header_bg: blue;
$cal_header_text: orange;
$cal_day_text: purple;
$cal_day_border: black;
$cal_day_border_hover: gray;
$cal_today_text: aqua;
$cal_today_bg: gray;
$cal_today_border: red;
$cal_link_border: pink;
$cal_link_border_hover: red;
$cal_link_text: red;
$cal_link_text_hover: pink;
$cal_link_bg: purple;
$cal_link_bg_hover: black;
$cal_month_text: orange;
$cal_month_text_hover: green;
$cal_month_bg: aqua;
$cal_month_bg_hover: purple;
$cal_month_border: pink;
$cal_month_border_hover: gray;


#calendar_wrap {
padding: 0px;
border: none;

  #wp-calendar {
  width:100%;
  font-size:12px;
  font-weight:300;
  border-collapse:separate;
  border-spacing:8px;
  border: 1px solid $cal_border;
  border-top: none;
  border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
    
    a {
    text-decoration: none;
    font-weight: 500;
    @include transition( all .5s ease );
    -webkit-border-radius:2px;
    -moz-border-radius:2px;
    -ms-border-radius:2px;
    -o-border-radius:2px;
    border-radius:2px;
    }//a
    
    caption {
    text-align: center;
    background-color: $cal_header_bg;
    color: $cal_header_text;
    border-radius: 5px 5px 0px 0px;
    -webkit-border-radius: 5px 5px 0px 0px;
    font-size: 16px;
    line-height: 24px;
    }//caption
  
    thead {
      th {
      font-size:13px;
      font-weight:600;
      color: $cal_day_text;
      text-align:center;
      padding-bottom: 5px;
      }//th
    }//thead
    
    tbody {
      tr {
        td {
        margin-bottom:10px;
        text-align:center;
        -webkit-border-radius:2px;
        -moz-border-radius:2px;
        -ms-border-radius:2px;
        -o-border-radius:2px;
        border-radius:2px;
        border:1px solid $cal_day_border;
        padding:6px;// change this for responsive sizes
        @include transition( border-color .3s ease );
          @media screen and (min-width: 768px) and (max-width: 991px){
          	padding: 6px 2px;
          }//@media
          
          &:hover {
          border-color:$cal_day_border_hover;
          }//&:hover
          
          &.pad {
          border:0;
          }//&.pad
          
          &#today {
          color: $cal_today_text;
          background-clip: $cal_today_bg;
          border-color: $cal_today_border;
          }//&#today
          
          &.has-link {
          padding: 0px;
          border: none;
            a {
            padding: 6px;
            display: block;
            margin: 0px;
            border:1px solid $cal_link_border;
            color: $cal_link_text;
            background-color: $cal_link_bg;
              @media screen and (min-width: 768px) and (max-width: 991px){
              	padding: 6px 2px;
              }//@media
              
              &:hover {
              border-color:$cal_link_border_hover;
              color: $cal_link_text_hover;
              background-color: $cal_link_bg_hover;
              }//&:hover
            }//a
          }//&.has-link
        }//td
      }//tr
    }//tbody
    
    tfoot {
      td {
      padding:0px;
        a {
        color: $cal_month_text;
        font-size:12px;
        position:relative;
        text-transform:uppercase;
        margin-bottom:1px;
        background-color: $cal_month_bg;
        display: inline-block;
        padding: 6px 10px;
        border: 1px solid $cal_month_border;
          &:hover {
          color: $cal_month_text_hover;
          background-color: $cal_month_bg_hover;
          border: 1px solid $cal_month_border_hover;
          }//&:hover
        }//a
        &#prev {
        text-align: left;
        }//&#prev
        &#next {
        text-align: right;
        }//&#prev
      }//td
    }//tfoot
    
  }//#wp-calendar
}//#calendar_wrap













