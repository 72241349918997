// -----------------------------------------------------------------------------
//
// Styled Content ("Kitchen Sink")
//
// This is for use on areas of content that need the "kitchen sink" 
// of styles or the full style guide (ex. single post content)
//
// Here we lay out any additional complexity or styles required
// for the full "kitchen sink" for typography, media, and embeds.
//
// Doing things this way allows us to cut down on the need for overwriting
// ourselves across the site and only applying these styles where they are 
// exactly needed because they have been targeted specifically or because
// we have wrapped our dynamic output (ex. single post content) in
// our .context-content wrapper class.
//
// USAGE:
//
// Super easy, wrap the output or content that needs the "kitchen sink"
// applied to it in a container and add our below helper class:
//
//
//
// -----------------------------------------------------------------------------

.sink,
%sink {
	font-family: $primary_font;
	color: $text;
	// -------------------------------------------------------------------------
	// Headings
	// -------------------------------------------------------------------------
	h1,h2,h3,h4,h5,h6 {
		color: $text;
		//letter-spacing: 1px;
	}
	h1 { 
		@extend %h1;
		font-weight: $font-weight-semibold;
		
		@include mq($mq-medium) {
			//margin: 72px 0 30px;
		}
		@include mq($mq-full) {
			//margin-bottom: 37px;
		}
	}

	h2 { 
		@extend %h2;
		//margin: 35px 0 20px;
		font-weight: $font-weight-semibold;
		@include mq($mq-medium) {
			//margin: 55px 0 30px;
		}
		@include mq($mq-full) {
			//margin: 72px 0 34px;
		}
	}
	
	h3 { 
		@extend %h3;
		//margin: 35px 0 20px;
		font-weight: $font-weight-semibold;
		@include mq($mq-medium) {
			//margin: 55px 0 25px;
		}
		@include mq($mq-full) {
			//margin-top: 72px;
		}
	}
	
	h4 { 
		@extend %h4;
		//margin: 35px 0 20px;
		//text-transform: uppercase;
		//letter-spacing: .05em;
		font-weight: $font-weight-semibold;
		@include mq($mq-medium) {
			//margin: 55px 0 25px;
			//letter-spacing: .075em;
		}
		@include mq($mq-full) {
			//margin-top: 72px;
		}
	}
	
	h5 { 
		@extend %h5;
		//margin: 35px 0 20px;
		font-weight: $font-weight-semibold;
		@include mq($mq-medium) {
			//margin: 55px 0 25px;
		}
		@include mq($mq-full) {
			//margin-top: 72px;
		}
	}
	
	h6 { 
		@extend %h6;
		//margin: 35px 0 20px;
		//text-transform: uppercase;
		//letter-spacing: .05em;
		font-weight: $font-weight-semibold;
		@include mq($mq-medium) {
			//margin: 55px 0 25px;
		}
		@include mq($mq-full) {
			//margin-top: 72px;
		}
	}

	// -------------------------------------------------------------------------
	// Paragraph Base Margins
	// -------------------------------------------------------------------------

	p {
		@extend %body-content;
		//margin: 25px 0;
		margin-bottom: 30px;
		line-height: $line-height-base;
		@include mq($mq-medium) {
			//margin: 35px 0;
		}
		@include mq($mq-full) {
			//margin: 40px 0;
		}
	}

	// -------------------------------------------------------------------------
	// Lists
	// -------------------------------------------------------------------------

	ul, ol {
		font-size: $font-size-content-s;
		line-height: 1.1875;
		//margin: 25px 0;
		margin-bottom: 20px;
		@include mq($mq-medium) {
			font-size: $font-size-content-m;
			//margin: 35px 0;
			//line-height: 1.2778;
		}
		@include mq($mq-full) {
			font-size: $font-size-content;
			//margin: 40px 0;
			//line-height:1.4;
		}

		li {
			//margin: 6px 0;
			margin: 5px 0;
			@include mq($mq-full) {
				//margin: 8px 0;
			}
    }

		ol, ul {
			margin: 0;
		}

	}

	dl {
		//margin: 25px 0;
		font-size: $font-size-content-s;
		line-height: $line-height-base-s;
		@include mq($mq-medium) {
			//margin: 35px 0;
			font-size:$font-size-base-m;
			line-height: 1.0556;
		}
		@include mq($mq-full) {
			//margin: 40px 0;
			line-height:1.2778;
		}
	}

	ul, .list-bullet { 
		@extend %list-bullet;
	}
	
	ol, .list-number { 
		@extend %list-number;
	}

	dt {
		letter-spacing: .05em;
		@include mq($mq-medium) {
			letter-spacing: 0;
		}
	}

	dd {
		@include mq($mq-full) {
			//margin: 13px 0 2px;
		}
	}

	// -------------------------------------------------------------------------
	// Quotes & Blockquotes
	// -------------------------------------------------------------------------

	blockquote {
		@extend %blockquote-text;
		p {
			@extend %quote-text;
		}
	}

	cite {
		font-size: 0.9em;
		font-style: italic;
	}

	// -------------------------------------------------------------------------
	// Media
	// -------------------------------------------------------------------------

	img {
		//margin-top: 0.3em;
		//margin-bottom: 10px;
		height: auto;
		display: block;
		&.alignleft,
		&.alignright {
			//@extend %media-float;
		}
	}

	figure {
		&.alignleft,
		&.alignright {
			//@extend %media-float;
		}
	}

	//iframe,
	//object,
	//embed,
	//video
	.wp-embed-wrap {
		//margin: $margin auto;
	}

	// Caption: Caption
	.wp-caption-text {
		font-size: 12px;
		line-height: 13px;
		@include mq($mq-medium) {
			font-size: 14px;
			line-height: 1.3571;
		}
	}
	
	

	// -------------------------------------------------------------------------
	// Code, Preformatted
	// -------------------------------------------------------------------------

	pre, code {
		margin: $margin 0;
	}
	
	
	
	
	.alignleft {
		display: inline;
		float: left;
	}
	
	.alignright {
		display: inline;
		float: right;
	}
	.alignnone {
		margin-bottom: 20px;
	}
	
	.aligncenter {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}
	@media screen and (max-width:767px){
		.alignleft,
		.alignright,
		.aligncenter {
			text-align: center;
			display: block;
			clear: both;
			float: none;
			margin: 0px auto !important;
			margin-bottom: 20px !important;
		}
	}
	
	p {
		.alignleft {
			margin-right: 20px;
			margin-bottom: 20px;
		}
		.alignright {
			margin-left: 20px;
			margin-bottom: 20px;
		}
	}
	
	
	.btn,
	p .btn {
		background-color: $primary;
		color: $white;
		text-transform: uppercase;
		font-size: 16px;
		display: inline-block;
		padding: 13px 20px;
		font-weight: $font-weight-semibold;
		letter-spacing: 1px;
		text-decoration: none !important;
		
		@include border-radius( 2px );
		@include transition( all .3s ease );
		&:hover,
		&:focus {
			background-color: darken( $primary, 10 );
		}
	}
	
	.btn-primary {
		@extend .btn;
	}
	.lead {
		font-size: 20px;
		font-weight: $font-weight-semibold;
	}
}
