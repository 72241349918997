
// -----------------------------------------------------------------------------
//
// Variables: Typography
//
// -----------------------------------------------------------------------------

$heading_font: $primary_font;

// -----------------------------------------------------------------------------
// Font Stacks
// -----------------------------------------------------------------------------

$font-family-sans-serif   : sans-serif !default;
$font-family-base         : $font-family-sans-serif;


	// -----------------------------------------------------------------------------
// Font Sizing
// -----------------------------------------------------------------------------

$font-size-base-new	: 20px !default;
$font-size-base-m   : 18px !default;
$font-size-base-s   : 16px !default;
$font-size-base-xs  : 15px !default;

$font-size-h1       : 42px !default;
$font-size-h1-m     : 32px !default;
$font-size-h1-s     : 21px !default;

$font-size-h2       : 34px !default;
$font-size-h2-m     : 26px !default;
$font-size-h2-s     : 19px !default;

$font-size-h3       : 24px !default;
$font-size-h3-m     : 22px !default;
$font-size-h3-s     : 17px !default;

$font-size-h4       : 20px !default;
$font-size-h4-m     : 19px !default;
$font-size-h4-s     : 14px !default;

$font-size-h5       : 19px !default;
$font-size-h5-m     : 18px !default;
$font-size-h5-s     : 15px !default;

$font-size-h6       : 17px !default;
$font-size-h6-m     : 17px !default;
$font-size-h6-s     : 13px !default;

$font-size-content  : 16px !default;
$font-size-content-m: 16px !default;
$font-size-content-s: 16px !default;

$font-size-quote    : 24px !default;
$font-size-quote-m  : 21px !default;
$font-size-quote-s  : 19px !default;

$font-size-hero     : 90px !default;
$font-size-hero-m   : 75px !default;
$font-size-hero-s   : 45px !default;

$font-size-form     : 16px;
$font-size-form-s   : 14px;

// -----------------------------------------------------------------------------
// Font Weights
// -----------------------------------------------------------------------------

$font-weight-light    : 300 !default;
$font-weight-regular  : 400 !default;
$font-weight-medium   : 500 !default;
$font-weight-semibold : 600 !default;
$font-weight-bold     : 700 !default;


// -----------------------------------------------------------------------------
// Line Heights
// -----------------------------------------------------------------------------

$line-height-base   : 1.5000 !default; // ~30px line-height @ 20px base font-size.
$line-height-medium : 1.4000 !default;
$line-height-base-s : 1.3125 !default;
$line-height-small  : 1.2000 !default;
$line-height-smaller: 1.1136 !default;
$line-height-even   : 1.0000 !default;


// -----------------------------------------------------------------------------
// Margins
// -----------------------------------------------------------------------------

$margin-smaller : 0.55em !default;
$margin-small   : 1em !default;
$margin-medium  : 2em !default;
$margin         : 3.1em !default;
