/* OPTIONAL CLASSES
nav-fixed
with-scroll
ns_bars-left
*/

#back_to_top {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
}



body.nav-padding {
	@media screen and (min-width: 768px) {
		padding-top: $body-padding;
	}
}


#nav_standard {
	height: auto;
	background-color: $nav_bar_bg;
	border-bottom: $nav_bar_border;
	z-index: 9999;
	overflow: visible;
	font-family: $nav_bar_font;
	@include box-sizing();
	@include transition(all .5s);
	@media screen and (min-width: 768px) {
		background-color: $nav_bar_bg_desk;
		border-bottom: $nav_bar_border_desk;
		
		&.nav-fixed {
			position: fixed;
			top: 0;
			left: 0;
		}
	}
	
}

.ns-wrapper {
	padding: 0;
	@extend .container;
	width: 100%;
	max-width: 1170px;
	@media screen and (min-width: 768px) {
		padding: 0 $gutter;
	}
	@media screen and (max-width: 991px) {
		width: 100%;
	}
}



#nav_logo {
	float: left;
	width: $logo_width;
	height: $logo_height;
	padding: $logo_padding 10px;
	display: inline-block;
	@include box-sizing();
	@include transition(all .5s);
	@media screen and (min-width: 768px) {
		height: $logo_height_desk;
		width: $logo_width_desk;
		padding: $logo_padding_desk 0;
		.scrolled .with-scroll & {
			height: $logo_height_desk_scrolled;
			width: $logo_width_desk_scrolled;
			padding: $logo_padding_desk_scrolled 0;
		}
	}
	.ns_bars-left & {
		@media screen and (max-width: 767px) {
			float: right;
		}
	}
}

.nl-image {
	display: inline-block;
	width: 100%;
	height: 100%;
	background-image: url($logo_image);
	background-repeat: no-repeat;
	background-position: left center;
	background-size: contain;
	@media screen and (min-width: 768px) {
		background-image: url($logo_image_desk);
	}
}

#ns_bars {
	display: block;
	height: $nav_height;
	width: $ns_bars_width;
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	border-left: $ns_bars_border;
	background-image: url($ns_bars_bg);
	background-repeat: no-repeat;
	background-position: 0 center;
	@include transition(all .5s);
	@media screen and (min-width: 768px) {
		display: none;
	}
	&.open {
		background-position: -$ns_bars_width center;
	}
	.ns_bars-left & {
		right: inherit;
		left: 0;
		border-left: none;
		border-right: $ns_bars_border;
	}
}





.ns-mobile{
	display: none;
	width: 100%;
	clear: both;
	background-color: $ns_mobile_bg_desk;
	@media screen and (min-width: 768px) {
		display: block;
		width: auto;
		clear: none;
		background-color: transparent;
	}
}










.ns-wrapper {
	.the-primary-menu {
	    overflow: auto;
	    width: 100%;
	    
  		@media screen and (min-width: 768px) {
	  		float: right;
	  		overflow: visible;
	  		width: auto;
  		}
		
	    > li:hover > a {
		    @media screen and (min-width: 768px) {
			    color: $anchor_hover_desk;
		    }
	    }
    
	    > li.current-menu-item > a,
	    > li.current-menu-parent > a {
			@media screen and (min-width: 768px) {
				color: $anchor_current_desk;
				font-weight: $anchor_current_weight_desk;
			}
	    }
    
		> li {
			display: inline-block;
			position: relative;
			float: left;
			width: 100%;
			display: block;
			@extend %content-box;
			@media screen and (min-width: 768px) {
				width: auto;
			}
			a {
				text-decoration: none;
				white-space: nowrap;
				color: $anchor_color;
				font-weight: $anchor_weight;
				outline: none;
				min-width: inherit;
				width: 100%;
				display: block;
				margin-left: 0;
				line-height: $anchor_line;
				padding: $anchor_padding;
				border-top: $anchor_border;
				display: inline-block;
				font-size: $anchor_size;
				@include transition(line-height .5s);
				@media screen and (min-width: 768px) {
					color: $anchor_color_desk;
					padding: 0;
					width: auto;
					border-top: none;
					margin-left: $anchor_margin_desk;
					line-height: $anchor_line_desk;
					.scrolled .with-scroll & {
						line-height: $anchor_line_desk_scrolled;
					}
				}
			}
      
			&:hover > .sub-menu {
				display: block;
				> li {
					&:hover {
						> .sub-menu {
							display: block;
						}
					}
				}
			}
      
      
			> .sub-menu {
				display: block;
				position: relative;
				top: inherit;
				left: inherit;
				border: none;
				background-color: $sub_bg;
				@media screen and (min-width: 768px) {
					display: none;
					position: absolute;
					top: 100%;
					left: 15px;
					background-color: $sub_bg_desk;
					min-width: 100%;
					border: $sub_bg_border_desk;
					border-top: none;
				}
				
				> li {
					&.current-menu-item {
						a {
							color: $sub_current;
							@media screen and (min-width: 768px) {
								color: $sub_current_desk;
							}
						}
					}
					a {
						padding-left: 10px;
						display: block;
						min-width: 100px;
						color: $sub_anchor;
						font-size: $sub_size;
						line-height: $sub_line;
						margin-left: 0;
						@media screen and (min-width: 768px) {
							color: $sub_anchor_desk;
							font-size: $sub_size_desk;
							line-height: $sub_line_desk;
							padding: $sub_padding_desk;
							&:hover {
								background: $sub_bg_hover_desk;
								color: $sub_anchor_hover_desk;
							}
						}
					}
					
					> .sub-menu {
						display: none !important;
					}
				}
			}
    	}
	}
	
}












