// -----------------------------------------------------------------------------
//
// Typography
//
// Here we lay out the base typographic styles needed around the site.
// Do note, however, that what happens here is us setting all these 
// styles up as (helper) classes and silent classes (@extends) for use
// anywhere across the site.
//
// Then, within our "kitchen sink" helper class (see below note), we
// add any additionally needed complexity and our "full" styles (think margins)
// required for the appropriate content (ex. single post content).
//
// Doing things this way allows us to cut down on the need for overwriting
// ourselves across the site and only applying these typographic styles 
// where they are exactly needed because they have been targeted specifically
// or because we have wrapped our dynamic output (ex. single post content)
// in our .context-content wrapper class.
//
// NOTE: See utilities/helper-classes/kitchen-sink.scss for the "kitchen sink"
//
// -----------------------------------------------------------------------------


// -----------------------------------------------------------------------------
//
// Headings
//
// -----------------------------------------------------------------------------

.h1, %h1,
.h2, %h2,
.h3, %h3,
.h4, %h4,
.h5, %h5,
.h6, %h6 {
	line-height: $line-height-smaller;
	font-weight: $font-weight-medium;
	font-family: $heading_font;
	margin-bottom: 20px;
	~ p, ~ ol, ~ ul, ~ dl {
		margin-top: 0;
	}
}

.h1,
%h1 {
	font-size: $font-size-h1-s;
	//font-weight: $font-weight-bold;
	@include mq($mq-medium) {
		//font-weight: $font-weight-medium;
		font-size: $font-size-h1-m;
	}
	@include mq($mq-full) {
		font-size: $font-size-h1;
	}
}

.h2,
%h2 {
	font-size: $font-size-h2-s;
	//font-weight: $font-weight-bold;
	@include mq($mq-medium) {
		font-size: $font-size-h2-m;
		//font-weight: $font-weight-medium;
	}
  @include mq($mq-full) {
      font-size: $font-size-h2;
  }
}

.h3,
%h3 {
  //line-height: $line-height-smaller;
  font-size: $font-size-h3-s;
  //font-weight: $font-weight-bold;
  @include mq($mq-medium) {
      font-size: $font-size-h3-m;
  }
	@include mq($mq-full) {
		//line-height: 1.2083;
		font-size: $font-size-h3;
	}
}

.h4,
%h4 {
  //line-height: $line-height-medium;
  //font-family: $font-family-sans-serif;
  font-size: $font-size-h4-s;
  //font-weight: $font-weight-semibold;
  @include mq($mq-medium) {
	  //line-height: 1.05;
      font-size: $font-size-h4-m;
  }
	@include mq($mq-full) {
		//line-height: 1.2;
		font-size: $font-size-h4;
	}
}

.h5,
%h5 {
	//font-family: $font-family-sans-serif;
	//font-weight: $font-weight-semibold;
  font-size: $font-size-h5-s;
  @include mq($mq-medium) {
      font-size: $font-size-h5-m;
  }
	@include mq($mq-full) {
		//line-height: 1.2632;
		font-size: $font-size-h5;
	}
}

.h6,
%h6 {
	//font-family: $font-family-sans-serif;
	//font-weight: $font-weight-semibold;
  font-size: $font-size-h6-s;
	//line-height: 1.25;
	@include mq($mq-medium) {
		font-size: $font-size-h6-m;
		//line-height: $line-height-smaller;
	}
	@include mq($mq-full) {
		//line-height: 1.1765;
		font-size: $font-size-h6;
	}
}

// -----------------------------------------------------------------------------
//
// Paragraph
//
// -----------------------------------------------------------------------------

p,
%body-content {
	font-size: $font-size-content-s;
	//line-height: $line-height-base-s;
	@include mq($mq-medium) {
		font-size: $font-size-content-m;
	}
	@include mq($mq-full) {
		font-size: $font-size-content;
		//line-height: $line-height-base;
	}
}

p {
	@include mq($mq-medium) {
		line-height: 1.4444;
	}
	@include mq($mq-full) {
		line-height: $line-height-base;
	}
}

// -----------------------------------------------------------------------------
//
// Lists
//
// -----------------------------------------------------------------------------

ul, ol, dl {
  @extend %body-content;
}

.list-bullet,
%list-bullet {
	list-style: disc outside;
	padding-left: 1em;
}

.list-number,
%list-number {
	list-style: decimal outside;
	padding-left: 1.15em;
}

// Definition Lists
dl {
	dt {
		font-weight: $font-weight-semibold;
		font-size: $font-size-base-xs;
		@include mq($mq-medium) {
			font-size: 17px;
		}
		@include mq($mq-full) {
			font-size: $font-size-base-m;
			line-height: 1.27777;
		}
	}
	dd {
		margin: 6px 0;
		@include mq($mq-full) {
			margin: 8px 0;
		}
		~ dt {
			margin-top: 25px;
		}
	}
}

// -----------------------------------------------------------------------------
//
// Quotes & Blockquotes
//
// -----------------------------------------------------------------------------

q,
blockquote {
  margin: 25px auto;
	@include mq($mq-medium) {
		margin: 55px 0;
	}
	@include mq($mq-full) {
		margin: 72px 0;
	}
}

q,
//blockquote p,
%quote-text {
  font-size: $font-size-quote-s;
	font-weight: $font-weight-light;
	font-style: italic;
	font-family: $heading_font;
	line-height: $line-height-medium;
  @include mq($mq-medium) {
    font-size: $font-size-quote-m;
	  line-height: 1.6;
  }
	@include mq($mq-full) {
		line-height: 1.5417;
		font-size: $font-size-quote;
	}
}

q {
    display: block;
}

//blockquote,
%blockquote-text {
    p {
        margin: 0;
        & ~ p {
            margin-top: 25px;
        }
    }
}






strong {
    font-weight: $font-weight-semibold;
}

em {
	font-style: italic;
}

del {
    color: lighten($text, 30%);
}

pre, code {
	font-size: $font-size-base-xs;
	@include mq($mq-medium) {
		font-size: $font-size-base-s;
	}
}