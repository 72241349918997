$gray_light: #DCDDDD;

.screen-reader-text {
	visibility: hidden;
	display: none;
}


.chapter-list {
	list-style: upper-alpha;
	a {
		margin-bottom: 10px;
		display: inline-block;
	}
	ul {
		list-style: decimal;
		padding-left: 30px;
	}
}




.clause-wrap {
	.cw-title {
		background-color: $primary;
		font-family: $font_open;
		color: $white;
		padding: 15px 20px;
		text-align: center;
		margin: 0;
		font-style: italic;
		font-weight: 500;
		font-size: 30px;
		sup {
			font-size: 65%;
			padding: 0 1px;
			//color: $text;
			position: relative;
			top: -0.55em;
		}
	}
	.cw-content {
		border: 2px solid $primary;
		padding: 25px 20px;
		text-align: justify;
		p:last-child {
			margin-bottom: 0;
		}
	}
}






#footnotes {
	//font-style: italic;
	font-size: 14px;
	font-family: $primary_font;
	margin-top: 40px;
	h5 {
		font-size: 14px;
		margin-bottom: 10px;
		font-weight: 700;
	}
	&.sink {
		ul {
			list-style: decimal ;
			padding-left: 0;
			li {
				margin-bottom: 10px;
				margin-left: 15px;
				padding-left: 10px;
				list-style: inherit;
				&:before {
					display: none;
				}
				p {
					line-height: 1.5;
					font-size: 14px;
				}
			}
		}
	}
}






.divider-dots {
	display: block;
	width: 100%;
	height: 20px;
	margin: 30px 0;
	text-align: center;
	line-height: 20px;
	font-weight: 900;
	z-index: -9999;
	color: $text;
	//background-color: darken( $white, 5 );
	&:before {
		content: "••••";
		letter-spacing: 10px;
	}
}




.ns-wrapper .the-primary-menu>li a {
	@media (min-width:768px) and (max-width: 900px) {
		font-size: 14px;
		margin-left: 13px;
	}
}





#pre_nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 30px;
	background-color: $primary;
	z-index: 99999;
	display: none;
	@media (min-width:768px) {
		display: block;
	}
	> .container {
		width: 100%;
		max-width: 1170px;
	}
	#announcement {
		float: left;
		p {
			color: $white;
			font-size: 12px;
			font-family: $primary_font;
			line-height: 30px;
			.icon {
				padding-right: 5px;
				position: relative;
				top: 1px;
			}
		}
	}
	#utilities {
		float: right;
		margin-left: 10px;
		.search-form {
			float: left;
			position: relative;
			margin-top: 5px;
			input {
				outline: none;
				height: 20px;
				line-height: 20px;
				border: none;
				padding-right: 20px;
				padding-left: 5px;
				@include border-radius(0);
				-webkit-appearance: none;
			}
			.search-submit {
				position: absolute;
				height: 20px;
				width: 20px;
				background-color: $white;
				top: 0;
				right: 0;
				border: none;
				outline: none;
				text-align: center;
				line-height: 20px;
				cursor: pointer;
				&:hover {
					color: $primary;
				}
			}
		}
		
		a {
			display: inline-block;
			color: $white;
			line-height: 30px;
			//margin-left: 15px;
			@include transition( all .3s ease );
			&:hover {
				//color: darken( $primary, 20 );
			}
			.icon {
				font-size: 18px;
				position: relative;
				top: 3px;
			}
		}
	}
}


#nav_standard.nav-fixed {
	@media (min-width:768px) {
		top: 30px;
	}
}




#post_footer {
	background-color: darken( $primary, 15 );
	text-align: center;
	color: $white;
	font-family: $primary_font;
	padding: 7px 0;
	p {
		line-height: 1.5;
		font-size: 12px;
		a {
			color: $white;
			&:hover {
				text-decoration: underline;
			}
		}
		span {
			padding: 0 5px;
		}
	}
}



#footer {
	background-color: $primary;
	padding: 40px 0;
	text-align: center;
	font-family: $primary_font;
	clear: both;
	border-top: 10px solid $secondary;
	.the-footer-menu {
		display: inline-block;
		margin-bottom: 10px;
		li {
			display: inline-block;
			float: left;
			font-size: 16px;
			margin-bottom: 15px;
			&:after {
				content: '/';
				color: $white;
				padding: 0 10px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
			a {
				color: $white;
				font-size: 16px;
				display: inline-block;
				&:hover {
					//color: lighten( $primary, 25 );
					text-decoration: underline;
				}
			}
		}
	}
	
	.btn-footer {
		display: inline-block;
		padding: 15px 30px;
		text-transform: uppercase;
		color: $primary;
		font-weight: $font-weight-bold;
		font-size: 18px;
		line-height: 18px;
		letter-spacing: 1px;
		white-space: nowrap;
		background-color: $white;
		@include border-radius( 3px );
		border: 2px solid $white;
		@include transition( all .3s ease );
		&:hover {
			background-color: transparent;
			color: $white;
		}
	}
	
	.privacy {
		//margin-top: 20px;
		a {
			color: $white;
			text-transform: uppercase;
			font-weight: $font-weight-bold;
			letter-spacing: 1px;
			font-size: 14px;
			&:hover {
				color: lighten( $primary, 15 );
			}
		}
	}
	
	.contact {
		li {
			display: block;
			color: $white;
			margin-bottom: 10px;
			@media (min-width:768px) {
				display: inline-block;
				&:after {
					content: '•';
					padding: 0 10px;
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
			}
			a {
				color: $white;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	
	.social-media {
		margin-top: 10px;
		a {
			color: $primary;
			display: inline-block;
			width: 40px;
			height: 40px;
			background-color: $white;
			@include border-radius( 500px );
			text-align: center;
			line-height: 42px;
			font-size: 24px;
			margin: 5px;
			margin-bottom: 0;
			border: 2px solid $white;
			@include transition( all .3s ease );
			&:hover {
				background-color: transparent;
				color: $white;
			}
		}
	}
}






#recent_article {
	background-color: #efefef;
	padding: 50px 0;
	h6 {
		font-family: $font_open;
		font-size: 15px;
		font-style: italic;
		margin-bottom: 10px;
		color: #3b3b3b;
	}
	h3 {
		font-size: 25px;
		font-weight: $font-weight-semibold;
		text-transform: uppercase;
		line-height: 1.3;
		margin-bottom: 10px;
		a {
			color: $primary;
		}
	}
	.featured-image {
		margin-bottom: 20px;
		display: inline-block;
	}
}



.btn,
.sink p .btn {
	background-color: $primary;
	color: $white;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 16px;
	display: inline-block;
	padding: 10px 15px;
	font-weight: $font-weight-semibold;
	letter-spacing: 1px;
	text-decoration: none !important;
	text-align: center;
	font-family: $primary_font;
	
	-webkit-transform: skewX(-20deg);
	-moz-transform: skewX(-20deg);
	-ms-transform: skewX(-20deg);
	-o-transform: skewX(-20deg);
	transform: skewX(-20deg);
	
	@include border-radius( 0px );
	@include transition( all .3s ease );
	&:hover,
	&:focus {
		background-color: darken( $primary, 10 );
	}
	
	span {
		display: block;
		-webkit-transform: skewX(20deg);
		-moz-transform: skewX(20deg);
		-ms-transform: skewX(20deg);
		-o-transform: skewX(20deg);
		transform: skewX(20deg);
	}
	&.btn-red {
		background-color: $secondary;
		&:hover,
		&:focus {
			background-color: darken( $secondary, 10 );
		}
	}
}

.btn-primary {
	@extend .btn;
}




#author_home {
	padding: 50px 0;
	h4 {
		margin-top: 30px;
		color: #3b3b3b;
		font-size: 25px;
		text-transform: uppercase;
		font-weight: $font-weight-semibold;
	}
	img {
		@media (min-width:768px) {
			width: 100%;
		}
	}
}



#columns_home {
	padding: 50px 0;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
	color: $white;
	position: relative;
	
	> .container {
		width: 100%;
		max-width: 1170px;
	}
	
	.table {
		display: table;
		table-layout: fixed;
		
		.table-cell {
			padding: 40px;
			width: 100%;
			@media (min-width:768px) {
				width: 50%;
				display: table-cell;
				vertical-align: middle;
				float: none;
				padding: 0 40px;
			}
		}
	}
	
	img {
		display: inline-block;
		margin-bottom: 20px;
	}
	
	p {
		color: $white;
		margin-bottom: 30px;
	}
	
	h4 {
		text-transform: uppercase;
		color: $white;
		font-size: 25px;
		letter-spacing: 1px;
		font-weight: $font-weight-semibold;
		margin-bottom: 10px;
	}
	
	.btn {
		background-color: $white;
		color: $primary;
		border: 2px solid $white;
		&:hover {
			background-color: transparent;
			color: $white;
		}
	}
	
	.divider {
		display: none;
		@media (min-width:768px) {
			position: absolute;
			background-color: $white;
			display: block;
			width: 1px;
			height: 70%;
			left: 50%;
			top: 15%;
		}
	}
}


#training_home {
	padding: 50px 0;
}





#mailchimp_home {
	padding: 5px 0 12px;
	background-color: $primary;
	color: $white;
	text-align: center;
	cursor: pointer;
	@include transition( all .3s ease );
	&:hover {
		background-color: darken( $primary, 10 );
	}
	&.open {
		background-color: $primary;
		cursor: inherit;
	}
	
	h4 {
		font-family: $primary_font;
		font-size: 20px;
		line-height: 1.2;
		margin: 0;
		.icon-email {
			padding: 0 15px;
			font-size: 26px;
			position: relative;
			top: 5px;
		}
		.icon-triangle-down {
			padding-left: 5px;
			padding: 0 15px;
			position: relative;
			top: 5px;
			font-size: 24px;
		}
	}
}


#mailchimp_home_slide {
	background-color: #efefef;
	-webkit-box-shadow:inset 0 0 10px 0 #BFBFBF;
	box-shadow:inset 0 0 10px 0 #BFBFBF;
	display: none;
	#mc_embed_signup {
		background: none;
		padding: 30px 0;
		h2 {
			margin: 0;
		}
		form {
			padding: 0;
		}
		.mc-field-group {
			padding-bottom: 20px;
			margin: 0;
			width: 100%;
		}
		.mce_inline_error {
			margin-bottom: 0 !important;
		}
		input {
			outline: none;
		}
		.button {
			@extend .btn;
			margin: 0;
			height: auto;
			line-height: inherit;
		}
		.indicates-required {
			text-align: left;
			margin: 10px 0;
		}
	}
}



#banner_home {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
	clear: both;
	@media screen and (max-width: 767px ) {
		background: none !important;
	}
	#banner_home_mobile {
		padding: 50px 0;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		@media screen and (min-width: 768px ) {
			background: none !important;
			text-align: left;
		}
	}
	.container {
		width: 100%;
		max-width: 1170px;
	}
	.book-image {
		display: inline-block;
		@media screen and (max-width: 767px ) {
			width: 300px;
			margin-bottom: 40px;
		}
	}
	@media screen and (min-width: 768px ) {
		.table {
			display: table;
			table-layout: fixed;
			.table-cell {
				display: table-cell;
				vertical-align: middle;
				float: none;
			}
		}
	}
	.btn {
		margin-bottom: 30px;
		@media screen and (min-width: 768px ) {
			margin-bottom: 0;
		}
	}
	
	h2 {
		font-family: $font_open;
		font-style: italic;
		font-size: 16px;
		color: $text;
		font-weight: 400;
	}
	h1 {
		text-transform: uppercase;
		font-size: 25px;
		font-style: italic;
	}
	.more-about {
		text-transform: uppercase;
		color: $primary;
		font-weight: $font-weight-semibold;
		letter-spacing: 2px;
		line-height: 1.4;
		font-size: 14px;
		@media screen and (min-width: 768px ) {
			font-size: 16px;
		}
		&.first {
			margin-left: 30px;
			white-space: nowrap;
		}
	}
	hr {
		border-collapse: $text;
	}
}






.sink {
	p {
		a {
			color: $primary;
			font-weight: $font-weight-semibold;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	sup {
		font-size: 65%;
		padding: 0 1px;
		//color: $text;
		position: relative;
		top: -0.55em;
	}
	sub {
		font-size: 65%;
		padding: 0 1px;
		//color: $text;
		position: relative;
		top: 0.35em;
	}
	.primary-text {
		color: $primary;
	}
}






#content_wrap {
	padding: 50px 0;
	
	clear: both;
	> .container {
		width: 100%;
		max-width: 1170px;
	}
}






.page-sidebar {
	background-color: $gray_light;
	padding: 30px;
	hr {
		margin: 50px 0;
	}
	
	.search-form {
		margin-bottom: 30px;
		float: left;
		position: relative;
		margin-top: 5px;
		width: 100%;
		input {
			outline: none;
			height: 40px;
			line-height: 40px;
			border: none;
			padding-right: 40px;
			padding-left: 5px;
			width: 100%;
			font-size: 16px;
			@include border-radius(0);
			-webkit-appearance: none;
		}
		.search-submit {
			position: absolute;
			height: 40px;
			width: 40px;
			background-color: $white;
			top: 0;
			right: 0;
			border: none;
			outline: none;
			text-align: center;
			line-height: 40px;
			cursor: pointer;
			font-size: 20px;
			&:hover {
				color: $primary;
			}
		}
	}
	
	.widgettitle {
		text-transform: uppercase;
		background-color: $secondary;
		padding: 5px 10px;
		color: $white;
		text-align: center;
		font-family: $font_open;
		font-size: 24px;
		clear: both;
	}
	
	.widget {
		margin-bottom: 30px;
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
		> ul {
			list-style: none;
			padding: 0;
			margin: 0;
			li {
				margin-bottom: 10px;
				text-transform: uppercase;
				font-weight: $font-weight-semibold;
				font-size: 16px;
				line-height: 1.5;
				&:last-child {
					margin-bottom: 0;
				}
				a {
					color: $primary;
					&:hover {
						color: darken( $primary, 10 );
					}
				}
			}
		}
	}
	
	#disclaimer {
		p {
			margin-bottom: 0;
			font-size: 14px;
		}
	}
}

#sidebar_book {
	
	h3 {
		color: $primary;
		text-transform: uppercase;
		font-style: italic;
		font-size: 20px;
		line-height: 1.3;
		margin: 0;
	}
	img {
		margin: 30px auto;
		display: block;
		width: 200px;
	}
}

#sidebar_recent {
	h6 {
		font-family: $font_open;
		font-style: italic;
		color: #3b3b3b;
		font-weight: 400;
		font-size: 16px;
	}
	h3 {
		text-transform: uppercase;
		font-size: 20px;
		line-height: 1.4;
		margin-bottom: 5px;
		a {
			color: $primary;
		}
	}
}







$error_title_color: #F34642;
$error_border: #F34642;
$error_bg: #F34642;
$error_box_color: $white;



#gravity {
	button,
	input,
	optgroup,
	select,
	textarea {
		color: inherit;
		font: inherit;
		margin: 0;
	}
	button {
		overflow: visible;
	}
	button,
	select {
		text-transform: none;
	}
	button,
	html input[type="button"],
	input[type="reset"],
	input[type="submit"] {
		-webkit-appearance: button;
		cursor: pointer;
	}
	button[disabled],
	html input[disabled] {
		cursor: default;
	}
	button::-moz-focus-inner,
	input::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
	input {
		line-height: normal;
	}
	input[type="checkbox"],
	input[type="radio"] {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		padding: 0;
	}
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		height: auto;
	}
	input[type="search"] {
		-webkit-appearance: textfield;
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
	}
	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	fieldset {
	
	}
	legend {
		border: 0;
		padding: 0;
	}
	textarea {
		overflow: auto;
	}
	optgroup {
		font-weight: bold;
	}
	
	
	[role="button"] {
		cursor: pointer;
	}
}


.gform_confirmation_message {
	color: $white;
	font-size: 24px;
	line-height: 1.5;
}



#gravity {
	color: $text;
	font-size: 16px;
	font-family: $primary_font;
	font-weight: 400;
	line-height: 1.7;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	input,
	textarea {
		margin-bottom: 0;
	}
	.ginput_complex {
		> span {
			width: 48.5%;
			margin: 0;
			float: left;
			&:first-child {
				margin-right: 3%;
				@media screen and (max-width: 400px) {
					margin-right: 0;
					padding-right: 0;
					margin-bottom: 10px;
				}
			}
			@media screen and (max-width: 400px) {
				width: 100%;
				margin-right: 0;
			}
		}
		span {
			label {
				float: left;
				font-weight: 500;
			}
		}
	}
	
	.validation_error,
	.gfield_error {
		margin-right: 0 !important;
		width: 100%;
	}
	.validation_error {
		padding: 15px;
		text-align: center;
		border: none;
		background-color: $error_bg;
		margin-bottom: 0;
		color: $error_box_color;
	}
	
	.gfield_error {
		background-color: transparent;
		border: none;
		label {
			color: $error_title_color;
		}
		
		label,
		.validation_message,
		.ginput_container {
			margin-left: 0;
		}
		.validation_message {
			margin-right: 0 !important;
			background-color: $error_bg;
			color: $error_box_color;
			max-width: 100%;
			width: 100%;
			margin-top: 0 !important;
			padding: 5px 10px !important;
			margin-bottom: 0 !important;
			font-size: 14px;
			font-weight: 400;
		}
		.instruction {
			margin-bottom: 10px !important;
		}
	}
	
	.gfield_required {
		color: $error_title_color;
	}
	
	.gform_wrapper li.gfield_error input,
	.gform_wrapper li.gfield_error textarea {
		border-color: $error_border;
		&:focus {
			//border-color: darken( $error_border, 25% );
			border-color: $black;
			border-color: $secondary;
		}
	}
	
	.ginput_container {
		max-width: 100%;
	}
	
	.gform_wrapper {
		width: 100%;
		max-width: 100%;
		margin: 0;
	}
	
	input,
	input[type=text],
	textarea {
		border-width: 2px !important;
		border-style: solid;
		border-color: #eaeaea;
		outline: none;
		float: left;
		height: 40px;
		width: 100% !important;
		padding: 5px 10px !important;
		box-shadow: none !important;
		-webkit-box-shadow: none !important;
		//.transition-3(all);
		@include transition( all .3s ease );
		&:focus {
			border-color: $secondary;
		}//&:focus
	}
	
	
	textarea {
		height: 140px;
	}//textarea
	
	
	input[type=submit] {
		border: none;
		color: #ffffff;
		background-color: $primary;
		text-transform: uppercase;
		font-size: 18px;
		font-weight: 500;
		&:hover {
			background-color: darken( $primary, 15% );
		}//&:hover
	}
	
	
	button {
		outline: none !important;
	}//button
	
	
	.gfield {
		width: 100%;
		float: left;
		margin-bottom: 20px !important;
		span {
			label {
				margin-bottom: 0 !important;
			}
		}
	}
	
	
	.gfield_label {
		font-weight: $font-weight-semibold;
	}
	
	.gform_wrapper.gf_browser_chrome .gfield_radio li input[type=radio] {
		margin: 0 !important;
		height: 30px;
		line-height: 30px;
		margin-left: 5px !important;
		margin-right: 10px !important;
		display: inline-block;
		width: auto !important;
	}
	.gfield_radio li label {
		line-height: 30px !important;
	}
	
	.gfield_radio > li {
		cursor: pointer !important;
	}
}




body .chapters {
	list-style: none;
	padding-left: 0;
	li {
		margin-bottom: 20px;
		margin-top: 0;
		
		a {
			color: $primary;
			font-weight: $font-weight-semibold;
			font-size: 20px;
			&:hover {
				color: darken( $primary, 10 );
			}
		}
		ul {
			list-style: none;
			margin-top: 20px;
		}
	}
}














.btn-wrap {
	> a {
		@extend .btn;
	}
	&.previous > a {
		background-color: $secondary;
		&:hover {
			background-color: darken( $secondary, 10 );
		}
	}
}

body {
	.single-title,
	.feed-title {
		font-size: 25px;
		margin-bottom: 10px;
		font-weight: $font-weight-bold;
		color: $primary;
		a {
			color: $primary;
			&:hover {
				color: darken( $primary, 10 );
			}
		}
	}
	.feed-title {
		margin-top: 10px;
		font-size: 20px;
	}
	.single-title {
		text-align: center;
	}
	.feed-date {
	}
	.single-date{ 
		text-align: center;
		display: block !important;
	}
	
	
	
	.date {
		font-style: italic;
		//font-family: $font_open;
		color: $secondary;
		font-weight: 400;
		display: inline-block;
		font-size: 18px;
		margin: 0;
		margin-bottom: 20px;
	}
	
	.single-categories {
		display: inline-block;
		padding-left: 0;
		margin-bottom: 40px;
		li {
			display: inline-block;
			font-size: 13px;
			font-style: italic;
			font-family: $font_open;
			&:after {
				content: '•';
				padding: 0 5px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
			a {
				color: $primary;
				&:hover {
					color: darken( $primary, 10 );
				}
			}
		}
	}
	
	
}


hr.feed {
	border-color: #eaeaea;
	margin: 40px 0;
}







.pagination {
	display: inline-block;
	padding-left: 0;
	li {
		display: inline-block;
		a,
		span {
			display: block;
			float: left;
			width: 40px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			border: 1px solid $text;
			border-right-width: 0;
			&:hover {
				background-color: #eaeaea;
			}
		}
		a {
			color: $primary;
		}
		&:last-child {
			a,
			span {
				border-right-width: 1px;
			}
		}
		&.disabled {
			display: none;
		}
		&.active {
			a,
			span {
				background-color: $primary;
				color: $white;
				cursor: not-allowed;
			}
		}
	}
}
.page_display_title {
	text-transform: uppercase !important;
	font-size: 28px !important;
}













#subscribe {
	width: 100%;
	margin-top: 30px;
	padding: 20px;
	font-size: 24px;
}



div.sharedaddy h3.sd-title:before {
	border: none !important;
}











#services {
	text-align: center;
	padding: 40px 0;
	background-color: #EAECEF;
	background-image: url('#{$images_dir}/noise.png');
	.home & {
		background-image: none;
		background-color: $white;
	}
	> .container {
		width: 100%;
		max-width: 1170px;
	}
	h2 {
		margin-bottom: 40px;
		font-size: 36px;
		.home & {
			margin-bottom: 10px;
		}
	}
	h3 {
		color: $secondary;
		font-style: italic;
		font-weight: 400;
		@media (min-width: 768px) {
			margin-bottom: 40px;
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			&.menu-item-has-children {
				font-size: 30px;
				> a {
					color: $primary;
					font-weight: $font-weight-bold;
					font-family: $font_open;
					&:hover {
						color: darken( $primary, 10 );
					}
				}
			}
			a {
				color: $text;
				font-weight: 400;
				&:hover {
					color: #999;
				}
			}
			ul {
				margin-top: 10px;
				.home & {
					display: none;
				}
				li {
					margin-bottom: 7px;
				}
				.current-menu-item {
					a {
						color: $primary;
						font-weight: $font-weight-semibold;
					}
				}
			}
		}
	}
	#menu-repair-menu {
		li.menu-item-has-children {
			> a {
				color: $secondary;
				&:hover {
					color: darken( $secondary, 10 );
				}
			}
			ul {
				.current-menu-item {
					a {
						color: $secondary;
						font-weight: $font-weight-semibold;
					}
				}
			}
		}
	}
	.service {
		margin: 40px 0;
		&:first-child {
			margin-top: 10px;
		}
		@media (min-width: 768px) {
			margin: 0;
		}
	}
	.service-icon {
		margin-bottom: 20px;
		img {
			display: inline-block;
		}
	}
}



.the-primary-menu {
	@media (min-width: 768px) {
		> li {
			&:after {
				content: '/';
				padding: 0 10px;
				color: $secondary;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}

.ns-wrapper .the-primary-menu>li>.sub-menu {
	@media (min-width: 768px) {
		left: -10px;
	}
}




#call_us,
#email_us {
	float: left;
	margin-left: 10px;
	background-color: $secondary;
	color: $white;
	font-family: $primary_font;
	line-height: 30px;
	padding: 0 10px;
	cursor: pointer;
	position: relative;
	&:hover {
		@media (min-width: 768px) {
			.hover {
				display: block;
			}
		}
	}
	a {
		&:hover {
			text-decoration: underline;
		}
	}
	.icon {
		position: relative;
		top: 2px;
	}
	.hover {
		cursor: text;
		display: none;
		position: absolute;
		right: 0;
		top: 100%;
		background-color: $secondary;
		text-align: right;
		li {
			white-space: nowrap;
			padding: 3px 10px;
		}
	}
}






#page_banner,
#advantage_banner {
	$banner_padding: 80px;
	padding: $banner_padding 30px;
	@include background-cover();
	position: relative;
	text-align: center;
	clear: both;
	&.secondary {
		padding: 50px 0 !important;
	}
	.home & {
		padding: 80px 0 120px;
	}
	@media (min-width: 768px) {
		$banner_padding: 120px;
		padding: ($nav_height_desk + $banner_padding) 0 $banner_padding;
		.home & {
			padding: ($nav_height_desk + $banner_padding) 0 $banner_padding;
		}
	}
	.overlay {
		@include overlay();
	}
	
	.page-display-title {
		background-color: $primary;
		background-color: rgba( $primary, .8 );
		display: inline-block;
		color: $white;
		font-family: $font_open;
		font-size: 32px;
		text-transform: uppercase;
		font-weight: $font-weight-bold;
		padding: 10px 40px;
		-webkit-transform: skewX(-20deg);
		-moz-transform: skewX(-20deg);
		-ms-transform: skewX(-20deg);
		-o-transform: skewX(-20deg);
		transform: skewX(-20deg);
		max-width: 90%;
		&.red {
			background-color: rgba( $secondary, .8 );
		}
		@media (min-width: 768px) {
			font-size: 48px;
			max-width: 100%;
		}
		span {
			display: block;
			-webkit-transform: skewX(20deg);
			-moz-transform: skewX(20deg);
			-ms-transform: skewX(20deg);
			-o-transform: skewX(20deg);
			transform: skewX(20deg);
		}
	}
	
	p {
		color: $white;
		font-family: $primary_font;
		font-size: 20px;
		display: inline-block;
		margin-top: 20px;
		@media (min-width: 768px) {
			width: 70%;
		}
	}
	
	h2 {
		color: $white;
		font-style: italic;
		 text-transform: uppercase;
		 font-family: $primary_font;
		 font-weight: $font-weight-bold;
		 margin-top: 20px;
		 font-size: 30px;
	}
	
	.red-stripe,
	.blue-stripe {
		position: absolute;
		background-repeat: no-repeat;
		//background-size: 100% 100%;
		background-size: contain;
		width: 20%;
		height: 70%;
	}
	
	.red-stripe {
		top: 0;
		left: 0;
		background-image: url('#{$images_dir}/red-stripe.png');
		background-position: left top;
	}
	.blue-stripe {
		bottom: 0;
		right: 0;
		background-image: url('#{$images_dir}/blue-stripe.png');
		background-position: right bottom;
	}
	.lennox {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}
}


#advantage_banner {
	.btn {
		border: 2px solid $white;
		margin: 30px 10px 0;
		
	}
}





.staff {
	.content {
		* {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	hr {
		border-color: #eaeaea;
	}
	&:last-child {
		hr {
			display: none;
		}
	}
}



.feed-single {
	background-color: $gray_light;
	border-top: 10px solid $primary;
	padding: 30px;
	margin-bottom: 40px;
	&:last-child {
		margin-bottom: 0;
	}
	img {
		margin-bottom: 20px;
		@media (min-width: 768px) {
			width: 100%;
		}
	}
}





#side_estimate,
#side_serve {
	margin-top: 30px;
	@include background-cover();
	text-align: center;
	display: table;
	table-layout: fixed;
	min-height: 350px;
	width: 100%;
	h4 {
		color: $white;
		text-transform: uppercase;
		font-size: 36px;
		font-family: $font_open;
		margin: 0;
		line-height: 1.2;
		margin-top: 10px;
		@media (min-width:768px) and (max-width:900px) {
			font-size: 26px;
		}
	}
	.inner {
		padding: 30px 20px;
		display: table-cell;
		vertical-align: bottom;
	}
}
#side_serve {
	.inner {
		vertical-align: middle;
	}
	.btn {
		background-color: $secondary;
		border: 2px solid $white;
		font-family: $font_open;
		padding: 10px 20px;
		margin-top: 10px;
		&:hover {
			background-color: darken( $secondary, 10 );
		}
	}
}



#testimonial {
	background-color: #EAECEF;
	background-image: url('#{$images_dir}/noise.png');
	padding: 40px 0;
	text-align: center;
}




.single-wrap {
	background-color: $gray_light;
	padding: 30px;
	.single-image-wrap {
		text-align: center;
		img {
			display: inline-block;
			margin-bottom: 20px;
		}
	}
}




#ad {
	text-align: center;
	background-repeat: repeat-x;
	background-size: contain;
	a {
		display: inline-block;
	}
}





.ns-mobile {
	.phone {
		@media (min-width:768px) {
			display: none;
		}
		li {
			text-decoration: none;
		    white-space: nowrap;
		    color: #fff;
		    font-weight: 600;
		    outline: 0;
		    min-width: inherit;
		    width: 100%;
		    margin-left: 0;
		    line-height: 30px;
		    padding: 10px;
		    border-top: 1px solid #fff;
		    display: inline-block;
		    font-size: 16px;
		}
	}
}

.profile-image {
	margin-bottom: 20px;
	@media (min-width:768px) {
		margin-bottom: 0;
	}
}










































